var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"text-body-1 font-weight-bold mb-4"},[_vm._v("Generate Report")]),_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"10px"}},[_c('v-select',{staticStyle:{"width":"200px"},attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","dense":"","outlined":"","color":"primary","label":"Select Report Type","items":_vm.reportTypes,"item-value":"key","item-text":"value","return-object":"","hide-details":""},on:{"change":_vm.onReportTypeChange},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),(_vm.form.type)?_c('v-menu',{attrs:{"close-on-content-click":false,"bottom":"","offset-y":"","origin":"top left","nudge-bottom":"10","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"primary","dot":"","overlap":"","value":_vm.isFilterApplied}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)],1)]}}],null,false,1595912024),model:{value:(_vm.filters.show),callback:function ($$v) {_vm.$set(_vm.filters, "show", $$v)},expression:"filters.show"}},[_c('v-card',{attrs:{"width":"340","outlined":_vm.$vuetify.theme.dark}},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v("Filters")]),_c('v-card-text',[(_vm.hasPermission('service'))?_c('v-autocomplete',{attrs:{"auto-select-first":"","menu-props":{
                  bottom: true,
                  offsetY: true,
                },"append-icon":"mdi-chevron-down","items":_vm.service_list,"item-text":"name","item-value":"id","flat":"","outlined":"","dense":"","return-object":"","label":"Service"},model:{value:(_vm.filters.service),callback:function ($$v) {_vm.$set(_vm.filters, "service", $$v)},expression:"filters.service"}}):_vm._e(),(_vm.hasPermission('payout_for'))?_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","dense":"","outlined":"","color":"primary","label":_vm.getFieldLabel('payout_for'),"items":_vm.payout_for,"item-value":"key","item-text":"value","clearable":"","return-object":""},model:{value:(_vm.filters.payout_for),callback:function ($$v) {_vm.$set(_vm.filters, "payout_for", $$v)},expression:"filters.payout_for"}}):_vm._e(),(_vm.hasPermission('state'))?_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","dense":"","outlined":"","color":"primary","label":_vm.getFieldLabel('state'),"items":_vm.state_list,"item-value":"id","item-text":"name","clearable":"","return-object":""},model:{value:(_vm.filters.state),callback:function ($$v) {_vm.$set(_vm.filters, "state", $$v)},expression:"filters.state"}}):_vm._e(),(_vm.hasPermission('status'))?_c('v-select',{attrs:{"menu-props":{ bottom: true, offsetY: true },"append-icon":"mdi-chevron-down","dense":"","outlined":"","color":"primary","label":_vm.getFieldLabel('status'),"items":_vm.status,"item-value":"key","item-text":"value","clearable":"","return-object":""},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}}):_vm._e(),(_vm.hasPermission('date'))?_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","max-width":"290px","min-width":"auto","offset-y":"","nudge-top":"20"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.filterDateRangeText,"label":_vm.getFieldLabel('date'),"append-icon":"mdi-calendar","clearable":"","outlined":"","dense":"","readonly":""},on:{"click:clear":function($event){_vm.filters.date_range = []}}},'v-text-field',attrs,false),on))]}}],null,false,204455329),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":"","range":"","scrollable":"","max":_vm.maxDate},on:{"input":_vm.onDateSelected},model:{value:(_vm.filters.date_range),callback:function ($$v) {_vm.$set(_vm.filters, "date_range", $$v)},expression:"filters.date_range"}})],1):_vm._e(),(
                  _vm.hasPermission(_vm.$keys.SALES_EXECUTIVE) ||
                  _vm.hasPermission(_vm.$keys.OPERATIONS_EXECUTIVE)
                )?_c('v-select',{attrs:{"auto-select-first":"","menu-props":{
                  bottom: true,
                  offsetY: true,
                },"append-icon":"mdi-chevron-down","items":_vm.executive_list,"item-text":"name","item-value":"id","outlined":"","dense":"","clearable":"","return-object":"","label":_vm.getFieldLabel(_vm.$keys.SALES_EXECUTIVE) ||
                  _vm.getFieldLabel(_vm.$keys.OPERATIONS_EXECUTIVE)},model:{value:(_vm.filters.executive),callback:function ($$v) {_vm.$set(_vm.filters, "executive", $$v)},expression:"filters.executive"}}):_vm._e(),(_vm.hasPermission('university'))?_c('v-autocomplete',{attrs:{"auto-select-first":"","menu-props":{
                  bottom: true,
                  offsetY: true,
                },"append-icon":"mdi-chevron-down","items":_vm.university_list,"search-input":_vm.university_search,"loading":_vm.university_list_loading,"item-text":"name","item-value":"id","outlined":"","dense":"","no-filter":"","clearable":"","return-object":"","label":_vm.getFieldLabel('university')},on:{"update:searchInput":function($event){_vm.university_search=$event},"update:search-input":function($event){_vm.university_search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"mx-3 my-1 text-caption"},[(_vm.university_list_loading)?_c('span',[_vm._v("Searching...")]):_c('span',[_vm._v(" No data available ")])])]},proxy:true}],null,false,2287836722),model:{value:(_vm.filters.university),callback:function ($$v) {_vm.$set(_vm.filters, "university", $$v)},expression:"filters.university"}}):_vm._e(),(_vm.hasPermission('eca'))?_c('v-autocomplete',{attrs:{"auto-select-first":"","menu-props":{
                  bottom: true,
                  offsetY: true,
                },"append-icon":"mdi-chevron-down","items":_vm.eca_list,"search-input":_vm.eca_search,"loading":_vm.eca_list_loading,"item-text":"name","item-value":"id","outlined":"","dense":"","no-filter":"","clearable":"","return-object":"","label":_vm.getFieldLabel('eca')},on:{"update:searchInput":function($event){_vm.eca_search=$event},"update:search-input":function($event){_vm.eca_search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('div',{staticClass:"mx-3 my-1 text-caption"},[(_vm.eca_list_loading)?_c('span',[_vm._v("Searching...")]):_c('span',[_vm._v(" No data available ")])])]},proxy:true}],null,false,378798225),model:{value:(_vm.filters.eca),callback:function ($$v) {_vm.$set(_vm.filters, "eca", $$v)},expression:"filters.eca"}}):_vm._e()],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-4",attrs:{"text":""},on:{"click":function($event){_vm.filters.show = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"px-4",attrs:{"color":"primary","text":""},on:{"click":_vm.applyFilter}},[_vm._v(" Apply ")])],1)],1)],1):_vm._e(),(_vm.appliedFiltersList.length)?_c('v-sheet',{staticClass:"mx-auto",attrs:{"max-width":"600","color":"transparent"}},[_c('v-slide-group',{attrs:{"show-arrows":""}},_vm._l((_vm.appliedFiltersList),function(filter){return _c('v-slide-item',{key:'filter-' + filter},[_c('v-chip',{staticClass:"mx-2 px-4"},[_vm._v(" "+_vm._s(filter)+" ")])],1)}),1)],1):_vm._e(),(_vm.isFilterApplied)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.clearFilter}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant-remove")])],1)]}}],null,false,312914536)},[_c('span',[_vm._v("Clear Filters")])]):_vm._e()],1),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"color":"primary","loading":_vm.btnLoading,"disabled":!this.form.type},on:{"click":_vm.submit}},[_vm._v(" Generate ")])],1)])]),_c('DataTable',{ref:"dataTable"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }