<template>
  <div>
    <div>
      <div class="text-body-1 font-weight-bold mb-4">Generate Report</div>
      <div class="d-flex justify-space-between mb-6">
        <!-- left -->
        <div class="d-flex align-center" style="gap: 10px">
          <!-- report type -->
          <v-select
            style="width: 200px"
            :menu-props="{ bottom: true, offsetY: true }"
            append-icon="mdi-chevron-down"
            dense
            outlined
            color="primary"
            label="Select Report Type"
            :items="reportTypes"
            v-model="form.type"
            item-value="key"
            item-text="value"
            return-object
            hide-details
            @change="onReportTypeChange"
          >
          </v-select>

          <v-menu
            v-if="form.type"
            v-model="filters.show"
            :close-on-content-click="false"
            bottom
            offset-y
            origin="top left"
            nudge-bottom="10"
            transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-badge color="primary" dot overlap :value="isFilterApplied">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-filter-variant</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <v-card width="340" :outlined="$vuetify.theme.dark">
              <v-card-title class="text-subtitle-1">Filters</v-card-title>
              <v-card-text>
                <!-- service -->
                <v-autocomplete
                  v-if="hasPermission('service')"
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="filters.service"
                  :items="service_list"
                  item-text="name"
                  item-value="id"
                  flat
                  outlined
                  dense
                  return-object
                  label="Service"
                ></v-autocomplete>

                <!-- payout for -->
                <v-select
                  v-if="hasPermission('payout_for')"
                  :menu-props="{ bottom: true, offsetY: true }"
                  append-icon="mdi-chevron-down"
                  dense
                  outlined
                  color="primary"
                  :label="getFieldLabel('payout_for')"
                  :items="payout_for"
                  v-model="filters.payout_for"
                  item-value="key"
                  item-text="value"
                  clearable
                  return-object
                >
                </v-select>

                <!-- state -->
                <v-select
                  v-if="hasPermission('state')"
                  :menu-props="{ bottom: true, offsetY: true }"
                  append-icon="mdi-chevron-down"
                  dense
                  outlined
                  color="primary"
                  :label="getFieldLabel('state')"
                  :items="state_list"
                  v-model="filters.state"
                  item-value="id"
                  item-text="name"
                  clearable
                  return-object
                >
                </v-select>

                <!-- status -->
                <v-select
                  v-if="hasPermission('status')"
                  :menu-props="{ bottom: true, offsetY: true }"
                  append-icon="mdi-chevron-down"
                  dense
                  outlined
                  color="primary"
                  :label="getFieldLabel('status')"
                  :items="status"
                  v-model="filters.status"
                  item-value="key"
                  item-text="value"
                  clearable
                  return-object
                >
                </v-select>

                <!-- filter by date range -->
                <v-menu
                  v-if="hasPermission('date')"
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="auto"
                  offset-y
                  nudge-top="20"
                >
                  <template v-slot:activator="{ attrs, on }">
                    <v-text-field
                      :value="filterDateRangeText"
                      :label="getFieldLabel('date')"
                      v-bind="attrs"
                      append-icon="mdi-calendar"
                      v-on="on"
                      clearable
                      outlined
                      dense
                      readonly
                      @click:clear="filters.date_range = []"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="primary"
                    v-model="filters.date_range"
                    no-title
                    range
                    scrollable
                    :max="maxDate"
                    @input="onDateSelected"
                  >
                  </v-date-picker>
                </v-menu>

                <!-- executive -->
                <v-select
                  v-if="
                    hasPermission($keys.SALES_EXECUTIVE) ||
                    hasPermission($keys.OPERATIONS_EXECUTIVE)
                  "
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="filters.executive"
                  :items="executive_list"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  clearable
                  return-object
                  :label="
                    getFieldLabel($keys.SALES_EXECUTIVE) ||
                    getFieldLabel($keys.OPERATIONS_EXECUTIVE)
                  "
                >
                </v-select>

                <!-- university -->
                <v-autocomplete
                  v-if="hasPermission('university')"
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="filters.university"
                  :items="university_list"
                  :search-input.sync="university_search"
                  :loading="university_list_loading"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  no-filter
                  clearable
                  return-object
                  :label="getFieldLabel('university')"
                >
                  <template v-slot:no-data>
                    <div class="mx-3 my-1 text-caption">
                      <span v-if="university_list_loading">Searching...</span>
                      <span v-else> No data available </span>
                    </div>
                  </template>
                </v-autocomplete>

                <!-- eca -->
                <v-autocomplete
                  v-if="hasPermission('eca')"
                  auto-select-first
                  :menu-props="{
                    bottom: true,
                    offsetY: true,
                  }"
                  append-icon="mdi-chevron-down"
                  v-model="filters.eca"
                  :items="eca_list"
                  :search-input.sync="eca_search"
                  :loading="eca_list_loading"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
                  no-filter
                  clearable
                  return-object
                  :label="getFieldLabel('eca')"
                >
                  <template v-slot:no-data>
                    <div class="mx-3 my-1 text-caption">
                      <span v-if="eca_list_loading">Searching...</span>
                      <span v-else> No data available </span>
                    </div>
                  </template>
                </v-autocomplete>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn class="px-4" text @click="filters.show = false">
                  Cancel
                </v-btn>
                <v-btn class="px-4" color="primary" text @click="applyFilter">
                  Apply
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>

          <v-sheet
            class="mx-auto"
            max-width="600"
            color="transparent"
            v-if="appliedFiltersList.length"
          >
            <v-slide-group show-arrows>
              <v-slide-item
                v-for="filter in appliedFiltersList"
                :key="'filter-' + filter"
              >
                <v-chip class="mx-2 px-4">
                  {{ filter }}
                </v-chip>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <v-tooltip bottom v-if="isFilterApplied">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon @click="clearFilter" v-bind="attrs" v-on="on">
                <v-icon>mdi-filter-variant-remove</v-icon>
              </v-btn>
            </template>
            <span>Clear Filters</span></v-tooltip
          >
        </div>

        <!-- right -->
        <div class="d-flex" style="gap: 10px">
          <v-btn
            color="primary"
            class="text-capitalize"
            :loading="btnLoading"
            @click="submit"
            :disabled="!this.form.type"
          >
            Generate
          </v-btn>
        </div>
      </div>
    </div>

    <DataTable ref="dataTable" />
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions,mapGetters } from "vuex";

import DataTable from "@/components/Reports/DataTable";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      btnLoading: false,
      dateMenu: false,

      reportTypes: [],
      service_list: [],
      executive_list: [],

      university_list: [],
      university_search: "",
      university_list_loading: false,

      eca_list: [],
      eca_search: "",
      eca_list_loading: false,

      filters: {
        show: false,
        status: null,
        date_range: [],
        service: null,
        executive: null,
        city: null,
        state: null,
        university: null,
        eca: null,
        payout_for: null,
      },

      form: {
        type: null,
        status: null,
        date_range: [],
        service: null,
        executive: null,
        city: null,
        state: null,
        university: null,
        eca: null,
        payout_for: null,
      },
    };
  },

  computed: {
    ...mapGetters({
      state_list: "getStateList",
    }),
    isFilterApplied() {
      const filters = this.form;
      return (
        filters.status ||
        filters.date_range.length ||
        filters.service ||
        filters.executive ||
        filters.city ||
        filters.state ||
        filters.university ||
        filters.eca ||
        filters.payout_for
      );
    },

    appliedFiltersList() {
      const filters = [];

      if (this.form.service) {
        filters.push(this.form.service.name);
      }

      if (this.form.executive) {
        filters.push(this.form.executive.name);
      }

      if (this.form.status) {
        filters.push(this.form.status.value);
      }

      if (this.form.date_range.length) {
        filters.push(this.dateRangeText);
      }

      if (this.form.state) {
        filters.push(this.form.state.name);
      }

      if (this.form.city) {
        filters.push(this.form.city.name);
      }

      if (this.form.university) {
        filters.push(this.form.university.name);
      }

      if (this.form.eca) {
        filters.push(this.form.eca.name);
      }

      if (this.form.payout_for) {
        filters.push(this.form.payout_for.value);
      }

      return filters;
    },

    maxDate() {
      const today = this.$moment();
      return today.format("YYYY-MM-DD");
    },

    dateRangeText() {
      const _filter_by_date = this.form.date_range.map((item) =>
        this.$moment(item).format("DD-MM-YYYY")
      );
      return _filter_by_date.join(" ~ ");
    },

    filterDateRangeText() {
      const _filter_by_date = this.filters.date_range.map((item) =>
        this.$moment(item).format("DD-MM-YYYY")
      );
      return _filter_by_date.join(" ~ ");
    },

    status() {
      const filter = this.getFilter("status");
      if (filter) {
        return filter.items;
      }

      return [];
    },
    state() {
      const filter = this.getFilter("state");
      if (filter) {
        return filter.items;
      }

      return [];
    },

    payout_for() {
      const filter = this.getFilter("payout_for");
      if (filter) {
        return filter.items;
      }

      return [];
    },
  },

  watch: {
    university_search: _.debounce(function (query) {
      if (this.university_list_loading) return;

      if (query && !this.form.university) {
        this.getUniversityList();
      }
    }, 1000),

    eca_search: _.debounce(function (query) {
      if (this.eca_list_loading) return;

      if (query && !this.form.eca) {
        this.getEcaBodyList();
      }
    }, 1000),

    "filters.show"(value) {
      if (value) {
        this.filters.status = this.form.status;
        this.filters.date_range = this.form.date_range;
        this.filters.service = this.form.service;
        this.filters.executive = this.form.executive;
        this.filters.city = this.form.city;
        this.filters.state = this.form.state;
        this.filters.university = this.form.university;
        this.filters.eca = this.form.eca;
        this.filters.payout_for = this.form.payout_for;
      } else {
        this.filters.status = null;
        this.filters.date_range = [];
        this.filters.service = null;
        this.filters.executive = null;
        this.filters.city = null;
        this.filters.state = null;
        this.filters.university = null;
        this.filters.eca = null;
        this.filters.payout_for = null;
      }
    },
  },

  mounted() {
    this.getReportTypes();
    this.getServiceList();
    this.$root.$on("onNavClicked", this.navClickHandler);
  },

  beforeDestroy() {
    this.$root.$off("onNavClicked", this.navClickHandler);
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
    }),

    navClickHandler(navKey) {
      if (navKey === "reports") {
        this.reloadData();
      }
    },

    resetForm() {
      this.form = {
        type: null,
        status: null,
        date_range: [],
        service: null,
        executive: null,
        city: null,
        state: null,
        university: null,
        eca: null,
        payout_for: null,
      };
    },

    applyFilter() {
      this.filters.show = false;

      this.form.status = this.filters.status;
      this.form.date_range = this.filters.date_range;
      this.form.service = this.filters.service;
      this.form.executive = this.filters.executive;
      this.form.city = this.filters.city;
      this.form.state = this.filters.state;
      this.form.university = this.filters.university;
      this.form.eca = this.filters.eca;
      this.form.payout_for = this.filters.payout_for;
    },

    clearFilter() {
      this.filters.show = false;
      this.form.status = null;
      this.form.date_range = [];
      this.form.service = null;
      this.form.executive = null;
      this.form.city = null;
      this.form.state = null;
      this.form.university = null;
      this.form.eca = null;
      this.form.payout_for = null;
    },

    reloadData() {
      if (this.$refs.dataTable) {
        this.$refs.dataTable.getReportList();
      }
    },

    onDateSelected() {
      if (this.filters.date_range.length === 2) this.dateMenu = false;
    },

    getFilter(key) {
      if (this.form.type) {
        return this.$_.find(this.form.type.filters, { key: key });
      }
      return null;
    },

    hasPermission(field) {
      const filter = this.getFilter(field);
      return filter ? true : false;
    },

    getFieldLabel(field) {
      if (this.form.type) {
        const filter = this.$_.find(this.form.type.filters, { key: field });
        return filter ? filter.value : "";
      }

      return "";
    },

    onReportTypeChange() {
      this.clearFilter();

      if (this.getFilter(this.$keys.SALES_EXECUTIVE)) {
        this.getExecutiveList(this.$keys.SALES_EXECUTIVE);
      }

      if (this.getFilter(this.$keys.OPERATIONS_EXECUTIVE)) {
        this.getExecutiveList(this.$keys.OPERATIONS_EXECUTIVE , this.$keys.OPERATIONS_HEAD);
      }
    },

    getReportTypes() {
      const onSuccess = (res) => {
        this.reportTypes = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.report.types, {
        onSuccess,
      });
    },

    getServiceList() {
      const onSuccess = (res) => {
        this.service_list = res.data.data;
      };

      const params = {
        apply_pagination: false,
      };

      return this.$request(this.$keys.GET, this.$urls.master.service.list, {
        params,
        onSuccess,
      });
    },

    getExecutiveList(role_executive, role_head) {
      const onSuccess = (res) => {
        this.executive_list = res.data.data;
      };

      const params = new URLSearchParams();
      params.append("apply_pagination", false);
      params.append("role", role_executive);
      if(role_head) params.append("role", role_head);

      return this.$request(this.$keys.GET, this.$urls.team.list, {
        params,
        onSuccess,
      });
    },

    getUniversityList() {
      this.university_list_loading = true;

      const onSuccess = (res) => {
        this.university_list = res.data.data;
      };

      const onFinally = () => {
        this.university_list_loading = false;
      };

      const params = {
        search: this.university_search,
      };

      return this.$request(this.$keys.GET, this.$urls.university.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getEcaBodyList() {
      this.eca_list_loading = true;

      const onSuccess = (res) => {
        this.eca_list = res.data.data;
      };

      const onFinally = () => {
        this.eca_list_loading = false;
      };

      const params = {
        search: this.eca_search,
      };

      return this.$request(this.$keys.GET, this.$urls.eca.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    submit() {
      if (this.form.type) {
        this.btnLoading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.resetForm();
          this.reloadData();
        };

        const onFinally = () => {
          this.btnLoading = false;
        };

        const form = this.form;
        const data = {
          report_type: form.type.key,
          service: form.service ? form.service.id : null,
          executive: form.executive ? form.executive.id : null,
          city: form.city ? form.city.id : null,
          state: form.state ? form.state.id : null,
          university: form.university ? form.university.id : null,
          eca: form.eca ? form.eca.id : null,
          status: form.status ? form.status.key : "",
          payout_for: form.payout_for ? form.payout_for.key : "",
        };

        const date_range = form.date_range;
        if (date_range) {
          if (date_range.length === 2) {
            data["from_date"] = date_range[0];
            data["to_date"] = date_range[1];
          } else {
            data["from_date"] = date_range[0];
          }
        }

        return this.$request(this.$keys.POST, this.$urls.report.create, {
          data,
          onSuccess,
          onFinally,
        });
      }
    },
  },
};
</script>