<template>
  <div>
    <!-- data table -->
    <v-data-table
      class="elevation-3 rounded-t-0"
      :items-per-page="page_count"
      :server-items-length="page_count"
      :loading="loading"
      loading-text="Please wait.... Loading"
      :headers="headers"
      :items="items"
      hide-default-footer
      mobile-breakpoint
    >
      <template v-slot:[`item.report_type`]="{ item }">
        {{ item.report_type_display }}
      </template>

      <template v-slot:[`item.filters`]="{ item }">
        <div v-if="item.filters.length">
          <div
            v-for="(filter, index) in item.filters"
            :key="index + filter.title"
          >
            {{ filter.title }}: {{ filter.text }}
          </div>
        </div>
        <v-icon v-else>mdi-minus</v-icon>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <div v-if="item.created">
          {{ $utils.getDateFormat(item.created) }}
        </div>
        <div v-else><v-icon>mdi-minus</v-icon></div>
      </template>

      <template v-slot:[`item.report_status`]="{ item }">
        <v-chip
          :text-color="item.report_status_display.text_color"
          :color="item.report_status_display.bg_color"
          small
        >
          {{ item.report_status_display.text }}
        </v-chip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex justify-center" style="gap: 10px">
          <v-btn
            small
            outlined
            color="primary"
            class="text-capitalize"
            v-for="action in actions(item)"
            :key="action.key"
            @click="actionHandler(action.key, item)"
          >
            <v-icon left v-if="action.icon">mdi-{{ action.icon }}</v-icon>
            {{ action.text }}
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <!-- pagination -->
    <v-container
      class="d-flex justify-space-between align-center px-0 mb-0 mt-4"
      fluid
    >
      <div class="d-flex align-center text-subtitle-2">
        <div class="mr-2">Rows per page</div>
        <div style="width: 75px">
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="itemsPerPageList"
            v-model="page_count"
            hide-details
            dense
            solo
            color="primary"
          ></v-select>
        </div>
        <div class="ml-6">
          Results: {{ pageStart }} - {{ pageEnd }} of {{ total_count }}
        </div>
      </div>
      <v-pagination
        v-model="page_number"
        :length="page_total"
        :total-visible="5"
        color="primary"
      >
      </v-pagination>
    </v-container>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { saveAs } from "file-saver";

import { reportTable } from "@/headers";
export default {
  data() {
    return {
      items: [],
      loading: false,

      page_total: 1,
      page_number: 1,
      total_count: 0,
      page_count: 10,
      itemsPerPageList: [5, 10, 15, 20, 30, 40, 50],

      selectedItem: null,
    };
  },

  computed: {
    headers() {
      return reportTable;
    },

    pageStart() {
      let start = (this.page_number - 1) * this.page_count + 1;
      if (start + 1 > this.total_count) start = this.total_count;
      return start;
    },

    pageEnd() {
      let end = this.pageStart + this.page_count - 1;
      if (end > this.total_count) end = this.total_count;
      return end;
    },
  },

  watch: {
    page_count() {
      this.getReportList();
    },

    page_number() {
      this.getReportList();
    },
  },

  mounted() {
    this.getReportList();
  },

  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
    }),

    actions(item) {
      return item.actions;
    },

    actionHandler(key, item) {
      switch (key) {
        case "download": {
          this.downloadReport(item);
          break;
        }
        case "delete": {
          this.deleteReport(item);
          break;
        }
        default:
          break;
      }
    },

    downloadReport(item) {
      const filename = this.$utils.getFilenameFromUrl(item.report_file);
      saveAs(item.report_file, filename);
    },

    deleteReport(item) {
      const onSuccess = (res) => {
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.getReportList();
      };

      const params = {
        report_id: item.id,
      };
      return this.$request(this.$keys.DELETE, this.$urls.report.delete, {
        params,
        onSuccess,
      });
    },

    getReportList() {
      this.loading = true;
      this.items = [];

      const onSuccess = (res) => {
        this.items = res.data.data;

        const page_info = res.data.page_info;
        this.page_total = page_info.page_total;
        this.page_number = page_info.page_number;
        this.total_count = page_info.total_count;
      };

      const onFinally = () => {
        this.loading = false;
      };

      const params = {
        page_number: this.page_number,
        page_count: this.page_count,
      };

      return this.$request(this.$keys.GET, this.$urls.report.list, {
        params,
        onSuccess,
        onFinally,
        cancel: true,
      });
    },
  },
};
</script>